import React from "react";
import { Separator } from 'components/PDFGenerationModal/PDFGenerationModal.styled'
import { formatDateDDMMYY } from 'helpers/dateHelpers'
import { 
  ItemText, 
  MainTitle, 
  SubTitle, 
  Subtitle2, 
  InitiativeTitle 
} from "../components/CountryReport.styled";
import i18n from 'i18n';
import MoneyTextMask from "components/MoneyTextMask";

const renderODS = (ods) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row'}}>
      {i18n.t('SDG')} &nbsp;
      {ods?.map((itemName, index) => (
        <p key={index}>{index !== 0 ? index < ods.length - 1 ? ', ' : <>&nbsp;{`${i18n.t('and_label')} `}</> : ''}{itemName}</p>
      ))}
    </div>
  )
};

const renderItems = (items) => {
  return items?.map((itemName, index) => {
    const [name, countryName] = itemName.split(', ');
    console.log(name, countryName)
    return (
      <ItemText key={index}>{countryName ? `${name}, ${i18n.t(countryName)}` : itemName}</ItemText>
    )
  })
};

const renderActivities = (activities) => {
  return (
    <ul>
      {activities?.map((itemName, index) => (
        <div key={index}>
          <li>
            <ItemText>{itemName === null ? i18n.t('activity_preparation') : itemName}</ItemText>
          </li>
        </div>
      
      ))}
    </ul>
  )
};

export const renderInitiative = (country, countryFlag, roleName, initiative) => {
  const logo = require(`assets/flags/${countryFlag}`); 
  return (
    <div style={{maxWidth: '950px'}}>
      <MainTitle>{i18n.t('ben_country_report_title1')} {initiative?.year}</MainTitle>
      <SubTitle>
        <div style={{flexDirection: 'row'}}> 
          <img src={logo?.default} width={30} height={25}  style={{marginRight: '10px'}}/>
          {i18n.t('ben_country_report_subtitle2', { countryName: i18n.t(country) })}
          <b> {roleName}</b>
        </div> 
      
      </SubTitle>
      <Separator />
      <p>{i18n.t('ben_country_report_initiative')}</p>
      <InitiativeTitle>'{initiative.name}'</InitiativeTitle>
      <Separator />

      <Subtitle2>{i18n.t('ben_country_report_ben_entities')}:</Subtitle2>
      {renderItems(initiative.beneficiaries)}

      <Separator />

      <Subtitle2>{i18n.t('ben_country_report_first_entities')}:</Subtitle2>
      {renderItems(initiative.firstProviders)}

      <Separator />

      <Subtitle2>{i18n.t('ben_country_report_second_entities')}:</Subtitle2>
      {renderItems(initiative.secondProviders)}

      <Separator />

      <Subtitle2>{i18n.t('ben_country_report_obj')}:</Subtitle2>
      <ItemText>{initiative.objective}</ItemText>

      <Separator />

      <Subtitle2>{i18n.t('ben_country_report_budget')}:</Subtitle2>
      <ItemText>{i18n.t('F1_S7_EU_budget')}: <MoneyTextMask value={initiative.ueBudget} /></ItemText>
      <ItemText>{i18n.t('F1_S7_counterpart_budget')}: <MoneyTextMask value={initiative.counterpartBudget} /></ItemText>
      <ItemText>{i18n.t('Total TCI budget')}: <MoneyTextMask value={initiative.totalBudget} /></ItemText>

      <Separator />

      <Subtitle2>{i18n.t('ben_country_report_duration')}:</Subtitle2>
      <ItemText>
        {formatDateDDMMYY(new Date(initiative.fromDate))}
        {' - '}
        {formatDateDDMMYY(new Date(initiative.toDate))}
      </ItemText>

      <Separator />

      <Subtitle2>{i18n.t('ben_country_report_contrib')}:</Subtitle2>
      <ItemText>
        {renderODS(initiative.ods)}
      </ItemText>

      <Subtitle2>{i18n.t('ben_country_report_activities', { countryName: i18n.t(country)})}:</Subtitle2>
      <ItemText>
        {renderActivities(initiative.activitiesInCountry)}
      </ItemText>

      <div style={{marginBottom: '50px'}} />
      <hr />
      <div style={{marginBottom: '200px'}} />
    </div>
  )
}