import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {CenteredModalButtonContainer } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import { ErrorButton, StyledButtonLink} from 'components/Buttons';
import { ExportToCsv } from 'export-to-csv';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ModalParticipantsLits = (props) => {

  const {t} = useTranslation();
  
  const convertToCSV = (list, txt) => {
    var data = [];
    
    list.forEach(participant => {
       if(participant.confirmed) {
          data.push({firstName: participant.firstName, 
                     lastName: participant.lastName,
                     nationality: t(participant.nationality.name),
                     city: participant.city,
                     organization: participant.organizationName,
                     entityType: t(`entity_type_${participant.entityType.id}`),
                     email: participant.email,
                     phone: participant.phone,
                     willAttend: participant.willAttend? t('Yes'): t('No')
                    })
       }
    })

    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      filename: t('F5_S7_Participants_list'),
      showLabels: true, 
      showTitle: true,
      title: t('F5_S7_Participants_list'),
      useTextFile: txt,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [t('F1_S2_First_name'), t('F1_S2_Last_Name'), t('F3_S7_Participant_nationality'), t('F1_S2_City'), t('organization'), t('F3_S7_Participant_type_entity'), t('forgot_password_email'), t('F1_S2_Phone_number'), t('F5_S7_Participant_attend')] 
    };
   
    const csvExporter = new ExportToCsv(options);
   
    csvExporter.generateCsv(data);
  }

  const showList = (list) => {
    var csv = [t('F1_S2_First_name'), t('F1_S2_Last_Name'), t('F3_S7_Participant_nationality'), t('F1_S2_City'), t('organization'), t('F3_S7_Participant_type_entity'), t('forgot_password_email'), t('F1_S2_Phone_number'), t('F5_S7_Participant_attend')];
    
    csv.join(',');
    csv = csv+'\n';

    list.forEach(participant => {
      if(participant.confirmed)
        csv = csv+`${participant.firstName},${participant.lastName},${t(participant.nationality.name)},${participant.city},${participant.organizationName},${t(`entity_type_${participant.entityType.id}`)},${participant.email},${participant.phone},${participant.willAttend? t('Yes'): t('No')}\n`
    })

    return csv;
  }

    return (
      <Modal
        {...props}
        centered
        size="xl"
        animation={false}
      >
        <Modal.Body>  
          <Form.Group>
            <Form.Label style={{marginTop: '20px', marginBottom: '20px'}}>{t('F5_S7_Participants_list')}</Form.Label>
            <Form.Control
              name="comment"
              as="textarea" 
              rows={10}
              value={showList(props.participantsList)}
              style={{resize: 'none'}}
            />
          </Form.Group> 

          <Col>
            <Row>
              <StyledButtonLink variant="link" onClick={() => {convertToCSV(props.participantsList,false)}}>
                {t('F5_S7_Download_CSV')}
              </StyledButtonLink>
            </Row>
            <Row>
              <StyledButtonLink variant="link" onClick={() => {convertToCSV(props.participantsList,true)}}>
                {t('F5_S7_Download_TXT')}
              </StyledButtonLink>
            </Row>
          </Col>
           
          <CenteredModalButtonContainer>
            <ErrorButton onClick={() => {props.onHide()}}>{t('Button_close')}</ErrorButton>
          </CenteredModalButtonContainer>

        </Modal.Body >
      </Modal>
    );
  }

export default ModalParticipantsLits; 