import React, { useState, useEffect } from 'react'
import Header from '../components/Header'

import PaginationController from 'components/PaginationController'

import PreformulationTable from 'pages/statistics/Preformulation/components/Table2'
import FormulationTable from 'pages/statistics/Formulation/components/Table1'
import ImplementationTable from 'pages/statistics/Execution/components/Table1'
import EntitiesByRoleTable from 'pages/statistics/Entities/components/Table2'
import EntitiesByFunctionTable from 'pages/statistics/Entities/components/Table3'
import EntitiesByTypeTable from 'pages/statistics/Entities/components/Table4'
import EntitiesFromCountryTable from 'pages/statistics/Entities/components/Table5'
import ActivitiesFromCountryTable from 'pages/statistics/Activities/components/Table1'
import ActivitiesByRoleTable from 'pages/statistics/Activities/components/Table3'
import ActivitiesByTypeTable from 'pages/statistics/Activities/components/Table4'
import BeneficiariesByTypeTable from 'pages/statistics/DirectBeneficiaries/components/Table6'
import BeneficiariesDetailTable from 'pages/statistics/DirectBeneficiaries/components/Table1'

import { getPreFormulationSummary } from 'services/initiative'
import { getFormulationSummary } from 'services/initiative'
import { getExecutionSummary } from 'services/initiative'
import { getEntitiesByRoleSummary } from 'services/initiative'
import { getEntitiesByFunctionSummary } from 'services/initiative'
import { getEntitiesByTypeSummary } from 'services/initiative'
import { getEntitiesDetail } from 'services/initiative'
import { getActivitiesDetail } from 'services/initiative'
import { getActivitiesByFormatSummary } from 'services/initiative'
import { getActivitiesByTypeSummary } from 'services/initiative'
import { getBeneficiaryByTypeSummary } from 'services/initiative'
import { getBeneficiariesDetail } from 'services/initiative'

import { useTranslation } from 'react-i18next'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  StyledDiv,
  TableSeparator,
  StyledSubtitle,
  StyledRow,
  StyledRow2,
  StyledSubtitle2,
} from '../../CommonStyled/Tabs.styled'

import _chunk from 'lodash/chunk'

import { table2TransformData } from '../../Formulation/helpers/helpers'
import { table1TransformData } from '../../Preformulation/helpers/helpers'

import { TARGET_YEARS } from 'constants/appConstants'

const CountrySummary = () => {
  const { t } = useTranslation()

  const [preFormulationData, setPreFormulationData] = useState(null)
  const [formulationData, setFormulationData] = useState(null)
  const [implementationData, setImplementationData] = useState(null)
  const [entitiesByRoleData, setEntitiesByRoleData] = useState(null)
  const [entitiesByFunctionData, setEntitiesByFunctionData] = useState(null)
  const [entitiesByTypeData, setEntitiesByTypeData] = useState(null)
  const [activitiesByRoleData, setActivitiesByRoleData] = useState(null)
  const [activitiesByTypeData, setActivitiesByTypeData] = useState(null)
  const [beneficiariesByTypeData, setBeneficiariesByTypeData] = useState(null)
  const [beneficiariesDetailData, setBeneficiariesDetailData] = useState(null)

  const [
    entitiesFromCountryPaginatedData,
    setEntitiesFromCountryPaginatedData,
  ] = useState([])
  const [
    entitiesFromCountryFlatData,
    setEntitiesFromCountryFlatData,
  ] = useState(null)
  const [
    entitiesFromCountryCurrentPageData,
    setEntitiesFromCountryCurrentPageData,
  ] = useState([])

  const [
    activitiesFromCountryPaginatedData,
    setActivitiesFromCountryPaginatedData,
  ] = useState([])
  const [
    activitiesFromCountryFlatData,
    setActivitiesFromCountryFlatData,
  ] = useState(null)
  const [
    activitiesFromCountryCurrentPageData,
    setActivitiesFromCountryCurrentPageData,
  ] = useState([])

  const [preFormulationWaiting, setPreFormulationWaiting] = useState(false)
  const [formulationWaiting, setFormulationWaiting] = useState(false)
  const [implementationWaiting, setImplementationWaiting] = useState(false)
  const [entitiesByRoleWaiting, setEntitiesByRoleWaiting] = useState(false)
  const [entitiesByFunctionWaiting, setEntitiesByFunctionWaiting] = useState(
    false,
  )
  const [entitiesByTypeWaiting, setEntitiesByTypeWaiting] = useState(false)
  const [entitiesFromCountryWaiting, setEntitiesFromCountryWaiting] = useState(
    false,
  )
  const [
    activitiesFromCountryWaiting,
    setActivitiesFromCountryWaiting,
  ] = useState(false)
  const [activitiesByRoleWaiting, setActivitiesByRoleWaiting] = useState(false)
  const [activitiesByTypeWaiting, setActivitiesByTypeWaiting] = useState(false)
  const [beneficiariesByTypeWaiting, setBeneficiariesByTypeWaiting] = useState(
    null,
  )
  const [beneficiariesDetailWaiting, setBeneficiariesDetailWaiting] = useState(
    null,
  )

  const waiting =
    preFormulationWaiting ||
    formulationWaiting ||
    implementationWaiting ||
    entitiesByRoleWaiting ||
    entitiesByFunctionWaiting ||
    entitiesByTypeWaiting ||
    entitiesFromCountryWaiting ||
    activitiesFromCountryWaiting ||
    activitiesByRoleWaiting ||
    activitiesByTypeWaiting ||
    beneficiariesByTypeWaiting ||
    beneficiariesDetailWaiting

  const getPreFormulationData = async (param) => {
    setPreFormulationWaiting(true)
    try {
      const response = await getPreFormulationSummary({ countryId: param })
      setPreFormulationData(table1TransformData(response, TARGET_YEARS))
    } catch (error) {
      console.log(error)
    } finally {
      setPreFormulationWaiting(false)
    }
  }

  const getFormulationData = async (param) => {
    setFormulationWaiting(true)
    try {
      const response = await getFormulationSummary({ countryId: param })
      setFormulationData(table2TransformData(response, TARGET_YEARS))
    } catch (error) {
      console.log(error)
    } finally {
      setFormulationWaiting(false)
    }
  }

  const getImplementationData = async (param) => {
    setImplementationWaiting(true)
    try {
      let response = await getExecutionSummary({ countryId: param })
      if (param !== null && param !== 0 && param !== '') {
        response = response.filter((item) => item.category !== 'countries')
      }
      setImplementationData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setImplementationWaiting(false)
    }
  }

  const getEntitiesByRoleData = async (param) => {
    setEntitiesByRoleWaiting(true)
    try {
      const response = await getEntitiesByRoleSummary({ countryId: param })
      setEntitiesByRoleData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setEntitiesByRoleWaiting(false)
    }
  }

  const getEntitiesByFunctionData = async (param) => {
    setEntitiesByFunctionWaiting(true)
    try {
      const response = await getEntitiesByFunctionSummary({ countryId: param })
      setEntitiesByFunctionData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setEntitiesByFunctionWaiting(false)
    }
  }

  const getEntitiesByTypeData = async (param) => {
    setEntitiesByTypeWaiting(true)
    try {
      const response = await getEntitiesByTypeSummary({ countryId: param })
      setEntitiesByTypeData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setEntitiesByTypeWaiting(false)
    }
  }

  const getEntitiesDetailData = async (value) => {
    setEntitiesFromCountryWaiting(true)
    let params = {}
    try {
      if (value?.countryId) params = { ...params, countryId: value.countryId }
      if (value?.year) params = { ...params, year: value.year }

      const response = await getEntitiesDetail(params)
      setEntitiesFromCountryPaginatedData(_chunk(response, 100))
      setEntitiesFromCountryFlatData(response)
      setEntitiesFromCountryCurrentPageData(_chunk(response, 100)[0])
    } catch (error) {
      console.log(error)
    } finally {
      setEntitiesFromCountryWaiting(false)
    }
  }

  const getActivitiesDetailData = async (value) => {
    setActivitiesFromCountryWaiting(true)
    let params = {}
    try {
      if (value?.countryId) params = { ...params, countryId: value.countryId }
      if (value?.year) params = { ...params, year: value.year }
      const response = await getActivitiesDetail(params)
      setActivitiesFromCountryPaginatedData(_chunk(response, 100))
      setActivitiesFromCountryFlatData(response)
      setActivitiesFromCountryCurrentPageData(_chunk(response, 100)[0])
    } catch (error) {
      console.log(error)
    } finally {
      setActivitiesFromCountryWaiting(false)
    }
  }

  const getActivitiesByRoleData = async (param) => {
    setActivitiesByRoleWaiting(true)
    try {
      const response = await getActivitiesByFormatSummary({ countryId: param })
      setActivitiesByRoleData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setActivitiesByRoleWaiting(false)
    }
  }

  const getActivitiesByTypeData = async (param) => {
    setActivitiesByTypeWaiting(true)
    try {
      const response = await getActivitiesByTypeSummary({ countryId: param })
      setActivitiesByTypeData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setActivitiesByTypeWaiting(false)
    }
  }

  const getBeneficiariesByTypeData = async (param) => {
    setBeneficiariesByTypeWaiting(true)
    try {
      const response = await getBeneficiaryByTypeSummary({ countryId: param })
      setBeneficiariesByTypeData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setBeneficiariesByTypeWaiting(false)
    }
  }

  const getBeneficiariesDetailData = async (value) => {
    setBeneficiariesDetailWaiting(true)
    let params = {}
    try {
      if (value?.countryId) params = { ...params, countryId: value.countryId }
      if (value?.year) params = { ...params, year: value.year }
      const response = await getBeneficiariesDetail(params)
      setBeneficiariesDetailData(response)
    } catch (error) {
      console.log(error)
    } finally {
      setBeneficiariesDetailWaiting(false)
    }
  }

  useEffect(() => {
    getData(null)
  }, [])

  const getData = (params) => {
    getPreFormulationData(params?.countryId)
    getFormulationData(params?.countryId)
    getImplementationData(params?.countryId)
    getEntitiesByRoleData(params?.countryId)
    getEntitiesByFunctionData(params?.countryId)
    getEntitiesByTypeData(params?.countryId)
    getEntitiesDetailData(params)
    getActivitiesDetailData(params)
    getActivitiesByRoleData(params?.countryId)
    getActivitiesByTypeData(params?.countryId)
    getBeneficiariesByTypeData(params?.countryId)
    getBeneficiariesDetailData(params)
  }

  return (
    <StyledDiv>
      <Row>
        <Header
          getData={getData}
          waiting={waiting}
          preFormulationData={preFormulationData}
          formulationData={formulationData}
          implementationData={implementationData}
          entitiesByRoleData={entitiesByRoleData}
          entitiesByFunctionData={entitiesByFunctionData}
          entitiesByTypeData={entitiesByTypeData}
          entitiesFromCountryData={entitiesFromCountryFlatData}
          activitiesFromCountryData={activitiesFromCountryFlatData}
          activitiesByRoleData={activitiesByRoleData}
          activitiesByTypeData={activitiesByTypeData}
          beneficiariesByTypeData={beneficiariesByTypeData}
          beneficiariesDetailData={beneficiariesDetailData}
        />
      </Row>
      <Col>
        <TableSeparator />
        <StyledSubtitle> {t('country_report_subtitle1')} </StyledSubtitle>
        <PreformulationTable
          data={preFormulationData}
          waiting={preFormulationWaiting}
        />
        <TableSeparator />
        <StyledSubtitle> {t('country_report_subtitle2')} </StyledSubtitle>
        <FormulationTable data={formulationData} waiting={formulationWaiting} />
        <TableSeparator />
        <StyledSubtitle> {t('country_report_subtitle3')} </StyledSubtitle>
        <ImplementationTable
          data={implementationData}
          waiting={implementationWaiting}
        />
        <TableSeparator />
        <StyledSubtitle2>{t('country_report_subtitle10')}</StyledSubtitle2>
        <TableSeparator />
        <EntitiesByRoleTable
          data={entitiesByRoleData}
          waiting={entitiesByRoleWaiting}
          setData={setEntitiesByRoleData}
        />
        <StyledRow2>{t('Statistics_footnote_3')}</StyledRow2>
        <TableSeparator />
        <EntitiesByTypeTable
          data={entitiesByTypeData}
          waiting={entitiesByTypeWaiting}
          setData={setEntitiesByTypeData}
        />
        <TableSeparator />
        <EntitiesByFunctionTable
          data={entitiesByFunctionData}
          waiting={entitiesByFunctionWaiting}
          setData={setEntitiesByFunctionData}
        />
        <StyledRow2>{t('Statistics_footnote_2')}</StyledRow2>
        <TableSeparator />
        <StyledSubtitle> {t('country_report_subtitle4')} </StyledSubtitle>
        <EntitiesFromCountryTable
          data={
            entitiesFromCountryFlatData
              ? entitiesFromCountryCurrentPageData
              : null
          }
          waiting={entitiesFromCountryWaiting}
          setData={setEntitiesFromCountryCurrentPageData}
        />
        {entitiesFromCountryFlatData?.length > 0 ? (
          <StyledRow>
            <PaginationController
              visibility={
                entitiesFromCountryFlatData
                  ? entitiesFromCountryWaiting
                    ? false
                    : true
                  : false
              }
              totalPages={entitiesFromCountryPaginatedData.length}
              onPageChange={(pageNumber) =>
                setEntitiesFromCountryCurrentPageData(
                  entitiesFromCountryPaginatedData[pageNumber],
                )
              }
            />
          </StyledRow>
        ) : null}
        <TableSeparator />

        <StyledSubtitle2>{t('country_report_subtitle11')}</StyledSubtitle2>
        <TableSeparator />
        <ActivitiesByRoleTable
          title={t('country_activities_format')}
          data={activitiesByRoleData}
          waiting={activitiesByRoleWaiting}
          setData={setActivitiesByRoleData}
        />
        <TableSeparator />
        <ActivitiesByTypeTable
          title={t('country_activities_type')}
          data={activitiesByTypeData}
          waiting={activitiesByTypeWaiting}
          setData={setActivitiesByTypeData}
        />
        <TableSeparator />
        <StyledSubtitle> {t('country_report_subtitle5')} </StyledSubtitle>
        <ActivitiesFromCountryTable
          data={
            activitiesFromCountryFlatData
              ? activitiesFromCountryCurrentPageData
              : null
          }
          waiting={activitiesFromCountryWaiting}
          setData={setActivitiesFromCountryCurrentPageData}
        />
        {activitiesFromCountryFlatData?.length > 0 ? (
          <StyledRow>
            <PaginationController
              visibility={
                activitiesFromCountryFlatData
                  ? activitiesFromCountryWaiting
                    ? false
                    : true
                  : false
              }
              totalPages={activitiesFromCountryPaginatedData.length}
              onPageChange={(pageNumber) =>
                setActivitiesFromCountryCurrentPageData(
                  activitiesFromCountryPaginatedData[pageNumber],
                )
              }
            />
          </StyledRow>
        ) : null}
        <TableSeparator />
        <StyledSubtitle2>{t('country_report_subtitle12')}</StyledSubtitle2>
        <TableSeparator />
        <BeneficiariesByTypeTable
          title={t('country_direct_ben_by_group')}
          data={beneficiariesByTypeData}
          waiting={beneficiariesByTypeWaiting}
          setData={setBeneficiariesByTypeData}
        />
        <TableSeparator />
        <StyledSubtitle> {t('country_report_subtitle13')}: </StyledSubtitle>
        <BeneficiariesDetailTable
          data={beneficiariesDetailData}
          waiting={beneficiariesDetailWaiting}
          setData={setBeneficiariesDetailData}
        />
      </Col>
    </StyledDiv>
  )
}

export default CountrySummary
