import React, { useState, useEffect } from 'react';
import { FieldArray, Formik } from 'formik';
import { validationSchema } from './Validations';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'stores';
import { saveFundSection } from 'services/funds';
import FormLayout from '../../FormLayout'
import FormContainer from 'components/FormContainer';
import Form from 'react-bootstrap/Form'
import { FormFielErrorMessage, RequiredMark, SeparatorDotted } from 'components/CommonStyled';
import { useTranslation } from 'react-i18next';
import { useConstantsFunds } from 'customHooks/useConstantsFunds';
import { Accordion, Col, Row } from 'react-bootstrap';
import { AccordionItem, AccordionItemBody, AccordionItemHeader, AccordionTitle } from 'components/CustomAccordion';
import { ErrorButton, PrimaryButton } from 'components/Buttons';
import { Separator, StyledContainer, StyledIntroParagraph, StyledSubtitle } from './Styled';
import htmlParser from "html-react-parser";
import TargetForm from './TargetForm';
import ConfirmCloseModal from 'components/ConfirmCloseModal';
import FormErrorMessage from 'components/FormErrorMessage/FormErrorMessage';

const targetObject = {
  targetGoalId: "",
  targetIndicatorList: null,
};

const formInitialValues = {
  goalPrimaryTypeId: '',
  goalSecondaryTypeId: '',
  primaryTargetList: [
    {
      targetGoalId: '',
      targetIndicatorList: null,
    }
  ],
  secondaryTargetList: [
    {
      targetGoalId: '',
      targetIndicatorList: null,
    }
  ],
  goal10TypeId: "10",
  goal10TargetList: [],
  goal17TypeId: "17",
  goal17TargetList: []
};

const Section11 = () => {
  const {t} = useTranslation();

  const { formStore } = useRootStore();
  const { id } = useParams();

  const [saved, setSaved] = useState(false);
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [modalRemove, setModalRemove] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const {
    developmentGoals,
    developmentGoalTargets,
    developmentGoalTargetIndicators,
  } = useConstantsFunds();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    let newValues = formInitialValues;
    if (formStore?.formSectionData) {
      newValues = {
        goalPrimaryTypeId: formStore?.formSectionData?.[0]?.goalPrimaryType?.id ?? '',
        goalSecondaryTypeId: formStore?.formSectionData?.[0]?.goalSecondaryType?.id ?? '',
        goal10TypeId: formStore?.formSectionData?.[0]?.goal10Type?.id ?? '',
        goal17TypeId: formStore?.formSectionData?.[0]?.goal17Type?.id ?? '',
        primaryTargetList: formStore?.formSectionData?.[0]?.primaryTargetList?.map(item => {
          return {
            targetGoalId: item?.targetGoal?.id ?? '',
            targetIndicatorList: item?.targetIndicatorList?.map(indicator => {
              return {
                selectedIndicator: !!indicator?.selectedIndicator,
                targetGoalIndicatorId: indicator?.targetGoalIndicator?.id ?? '',
              }
            })
          }
        }),
        secondaryTargetList: formStore?.formSectionData?.[0]?.secondaryTargetList?.map(item => {
          return {
            targetGoalId: item?.targetGoal?.id ?? '',
            targetIndicatorList: item?.targetIndicatorList?.map(indicator => {
              return {
                selectedIndicator: !!indicator?.selectedIndicator,
                targetGoalIndicatorId: indicator?.targetGoalIndicator?.id ?? '',
              }
            })
          }
        }),
        goal10TargetList: formStore?.formSectionData?.[0]?.goal10TargetList?.map(item => {
          return {
            targetGoalId: item?.targetGoal?.id ?? '',
            targetIndicatorList: item?.targetIndicatorList?.map(indicator => {
              return {
                selectedIndicator: !!indicator?.selectedIndicator,
                targetGoalIndicatorId: indicator?.targetGoalIndicator?.id ?? '',
              }
            })
          }
        }),
        goal17TargetList: formStore?.formSectionData?.[0]?.goal17TargetList?.map(item => {
          return {
            targetGoalId: item?.targetGoal?.id ?? '',
            targetIndicatorList: item?.targetIndicatorList?.map(indicator => {
              return {
                selectedIndicator: !!indicator?.selectedIndicator,
                targetGoalIndicatorId: indicator?.targetGoalIndicator?.id ?? '',
              }
            })
          }
        }),
      }
    }
    setInitialValues(newValues);
  }, [formStore.formSectionData])

  useEffect(() => {
    if(saved)
      window.scrollTo(0, 0);
  }, [saved])

  const onSubmit = async (values, {resetForm}) => {
    try {
      const { complete } = await saveFundSection(id, 11, values);
      formStore.setSectionStatus(11, complete)
      resetForm({values});
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSubmit = (e, isValid, values, { resetForm }) => {
    e.preventDefault();
    isValid
      ? onSubmit(values, { resetForm })
      : alert(t('not_saved_alert'));
  }

  const filterDevGoalsTargets = (goalTypeId) => {
    return developmentGoalTargets.filter(
      (e) => e.developmentGoal.id == goalTypeId
    );
  };

  const openModalRemove = (key, arrayHelpers) => {
    setSelectedKey({
      currentKey: key,
      currentArrayHelper: arrayHelpers,
    });
    setModalRemove(true);
  };

  const closeModalRemove = () => {
    setModalRemove(false);
    setSelectedKey(null);
  };

  const setTargetIdsAndIndicatorsEmpty = (targetListRef) => {
    targetListRef?.forEach(target => {
      target.targetGoalId = '';
      target.targetIndicatorList = [];
    });
  };

  return (
    <FormLayout>
      {modalRemove && (
        <ConfirmCloseModal
          show={modalRemove}
          text={`${t("Delete_target")} ${selectedKey.currentKey + 1}?`}
          handleActionOnConfirm={() => {
            selectedKey.currentArrayHelper.remove(selectedKey.currentKey);
            closeModalRemove();
          }}
          handleCloseConfirm={closeModalRemove}
        />
      )}

      <FormContainer
        number={11}
        title={t('F3_S11_title')}
        formId="form55section11"
        showSavedMessage={saved}
        showSaveButton
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {( {values,
              errors,
              touched,
              dirty,
              resetForm,
              handleBlur,
              setFieldValue,
              isValid }) =>
              {
                console.log(values)
                return (
                  <form
                    onSubmit={(e) => {onValidateSubmit(e, isValid, values, { resetForm })}}
                    id="form55section11"
                  >
                    {dirty && (setSaved(false))}

                    <StyledContainer>
                      <StyledIntroParagraph>
                        {htmlParser(t("funds_s11_intro"))}
                      </StyledIntroParagraph>

                      <StyledSubtitle>
                        {htmlParser(t("F3_S11_Subtitle_SDG_primary"))}<RequiredMark>*</RequiredMark>
                      </StyledSubtitle>
                      <Separator />
                      <Form.Group as={Row}>
                        <Col>
                          <Form.Control
                            name="goalPrimaryTypeId"
                            as="select"
                            onChange={(e) => {
                              setFieldValue('goalPrimaryTypeId', e?.target?.value)
                              setTargetIdsAndIndicatorsEmpty(values.primaryTargetList);
                            }}
                            onBlur={handleBlur}
                            value={values.goalPrimaryTypeId}
                            className={
                              touched.goalPrimaryTypeId &&
                              errors.goalPrimaryTypeId
                                ? "error"
                                : null
                            }
                          >
                            <option value="">{t("Placeholder_select")}</option>
                            {developmentGoals.map((devGoal) => {
                              if (devGoal.id == 17) return null;
                              return (
                                <option key={devGoal.id} value={devGoal.id}>
                                  {t(`SDG_Goal${devGoal.id}`)}
                                </option>
                              )
                            })}
                          </Form.Control>
                          <FormErrorMessage
                            touched={touched.goalPrimaryTypeId}
                            errors={errors.goalPrimaryTypeId}
                          />
                        </Col>
                      </Form.Group>
                      <FormFielErrorMessage>
                        {t('F3_S11_validation2')}
                      </FormFielErrorMessage>
                      <FieldArray
                        name="primaryTargetList"
                        render={(arrayHelpers) => (
                          <>
                            <Accordion>
                              {values.primaryTargetList &&
                              values.goalPrimaryTypeId
                                ? values.primaryTargetList.map(
                                    (target, key) => (
                                      <AccordionItem key={key + 1}>
                                        <AccordionItemHeader>
                                          <AccordionTitle eventKey={key + 1}>
                                            {t("F3_S11_Target")} {key + 1}
                                          </AccordionTitle>
                                        </AccordionItemHeader>
                                        <Accordion.Collapse eventKey={key + 1}>
                                          <AccordionItemBody>
                                            <TargetForm
                                              targetListName={"primaryTargetList"}
                                              targetIndex={key}
                                              goalId={values.goalPrimaryTypeId}
                                              developmentGoalTargets={filterDevGoalsTargets(
                                                values.goalPrimaryTypeId
                                              )}
                                              targetIndicatorsList={
                                                developmentGoalTargetIndicators
                                              }
                                              setSaved={setSaved}
                                            />
                                            <ErrorButton
                                              onClick={() =>
                                                openModalRemove(key, arrayHelpers)
                                              }

                                            >
                                              {t("F3_S11_Target_button_delete")}
                                            </ErrorButton>
                                          </AccordionItemBody>
                                        </Accordion.Collapse>
                                      </AccordionItem>
                                    )
                                  )
                                : null}
                            </Accordion>
                            {typeof errors.primaryTargetList === "string" ? (
                              <FormErrorMessage
                                touched
                                errors={errors.primaryTargetList}
                              />
                            ) : null}

                            <SeparatorDotted />
                            <PrimaryButton
                              onClick={() => arrayHelpers.push(targetObject)}
                              disabled={
                                !values.goalPrimaryTypeId
                              }
                            >
                              {t("F3_S11_Target_button_add")}
                            </PrimaryButton>
                          </>
                        )}
                      />

                      <SeparatorDotted />

                      <StyledSubtitle>
                        {t("F3_S11_Subtitle_SDG_secondary")}<RequiredMark>*</RequiredMark>
                      </StyledSubtitle>
                      <Separator />
                      <Form.Group as={Row}>
                        <Col>
                          <Form.Control
                            name="goalSecondaryTypeId"
                            as="select"
                            onChange={(e) => {
                              setFieldValue('goalSecondaryTypeId', e?.target?.value)
                              setTargetIdsAndIndicatorsEmpty(values.secondaryTargetList);
                            }}
                            onBlur={handleBlur}
                            value={values.goalSecondaryTypeId}
                            className={
                              touched.goalSecondaryTypeId &&
                              errors.goalSecondaryTypeId
                                ? "error"
                                : null
                            }
                          >
                            <option value="">{t("Placeholder_select")}</option>
                            {developmentGoals.map((devGoal) => {
                              if (devGoal.id == 17) return null;
                              return (
                                <option key={devGoal.id} value={devGoal.id}>
                                  {t(`SDG_Goal${devGoal.id}`)}
                                </option>
                              )
                            })}
                          </Form.Control>
                          <FormErrorMessage
                            touched={touched.goalSecondaryTypeId}
                            errors={errors.goalSecondaryTypeId}
                          />
                        </Col>
                      </Form.Group>
                      <FormFielErrorMessage>
                        {t('F3_S11_validation2')}
                      </FormFielErrorMessage>
                      <FieldArray
                        name="secondaryTargetList"
                        render={(arrayHelpers) => (
                          <>
                            <Accordion>
                              {values.secondaryTargetList &&
                              values.goalSecondaryTypeId
                                ? values.secondaryTargetList.map(
                                    (target, key) => (
                                      <AccordionItem key={key + 1}>
                                        <AccordionItemHeader>
                                          <AccordionTitle eventKey={key + 1}>
                                            {t("F3_S11_Target")} {key + 1}
                                          </AccordionTitle>
                                        </AccordionItemHeader>
                                        <Accordion.Collapse eventKey={key + 1}>
                                          <AccordionItemBody>
                                            <TargetForm
                                              targetListName={"secondaryTargetList"}
                                              targetIndex={key}
                                              goalId={values.goalSecondaryTypeId}
                                              developmentGoalTargets={filterDevGoalsTargets(
                                                values.goalSecondaryTypeId
                                              )}
                                              targetIndicatorsList={
                                                developmentGoalTargetIndicators
                                              }
                                              setSaved={setSaved}
                                            />
                                            <ErrorButton
                                              onClick={() =>
                                                openModalRemove(key, arrayHelpers)
                                              }

                                            >
                                              {t("F3_S11_Target_button_delete")}
                                            </ErrorButton>
                                          </AccordionItemBody>
                                        </Accordion.Collapse>
                                      </AccordionItem>
                                    )
                                  )
                                : null}
                            </Accordion>
                            {typeof errors.secondaryTargetList === "string" ? (
                              <FormErrorMessage
                                touched
                                errors={errors.secondaryTargetList}
                              />
                            ) : null}

                            <SeparatorDotted />
                            <PrimaryButton
                              onClick={() => {
                                arrayHelpers.push(targetObject);
                              }}
                              disabled={
                                !values.goalSecondaryTypeId
                              }
                            >
                              {t("F3_S11_Target_button_add")}
                            </PrimaryButton>
                          </>
                        )}
                      />

                      <SeparatorDotted />

                      <StyledSubtitle>
                        {t("F3_S11_Subtitle_SDG_10")}
                      </StyledSubtitle>
                      <Separator />
                      <Form.Group as={Row}>
                        <Col>
                          <Form.Control
                            name="goal10TypeId"
                            as="select"
                            value="10"
                            disabled
                          >
                            <option value="">{t("Placeholder_select")}</option>
                            {developmentGoals.map((devGoal) => (
                              <option key={devGoal.id} value={devGoal.id}>
                                {t(`SDG_Goal${devGoal.id}`)}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <FieldArray
                        name="goal10TargetList"
                        render={(arrayHelpers) => (
                          <>
                            <Accordion>
                              {values.goal10TargetList &&
                              values.goal10TypeId
                                ? values.goal10TargetList.map((target, key) => (
                                    <AccordionItem key={key + 1}>
                                      <AccordionItemHeader>
                                        <AccordionTitle eventKey={key + 1}>
                                          {t("F3_S11_Target")} {key + 1}
                                        </AccordionTitle>
                                      </AccordionItemHeader>
                                      <Accordion.Collapse eventKey={key + 1}>
                                        <AccordionItemBody>
                                          <TargetForm
                                            targetListName={"goal10TargetList"}
                                            targetIndex={key}
                                            goalId="10"
                                            developmentGoalTargets={filterDevGoalsTargets("10")}
                                            targetIndicatorsList={
                                              developmentGoalTargetIndicators
                                            }
                                            setSaved={setSaved}
                                          />
                                          <ErrorButton
                                            onClick={() =>
                                              openModalRemove(key, arrayHelpers)
                                            }

                                          >
                                            {t("F3_S11_Target_button_delete")}
                                          </ErrorButton>
                                        </AccordionItemBody>
                                      </Accordion.Collapse>
                                    </AccordionItem>
                                  ))
                                : null}
                            </Accordion>
                            {typeof errors.goal10TargetList === "string" ? (
                              <FormErrorMessage
                                touched
                                errors={errors.goal10TargetList}
                              />
                            ) : null}
                            <SeparatorDotted />
                            <PrimaryButton
                              onClick={() => arrayHelpers.push(targetObject)}

                            >
                              {t("F3_S11_Target_button_add")}
                            </PrimaryButton>
                          </>
                        )}
                      />

                      <SeparatorDotted />

                      <StyledSubtitle>
                        {t("F3_S11_Subtitle_SDG_17")}
                        <RequiredMark>*</RequiredMark>
                      </StyledSubtitle>
                      <Separator />
                      <Form.Group as={Row}>
                        <Col>
                          <Form.Control
                            name="goal17TypeId"
                            as="select"
                            value="17"
                            disabled
                          >
                            <option value="">{t("Placeholder_select")}</option>
                            {developmentGoals.map((devGoal) => (
                              <option key={devGoal.id} value={devGoal.id}>
                                {t(`SDG_Goal${devGoal.id}`)}
                              </option>
                            ))}
                          </Form.Control>
                          <FormErrorMessage
                            touched={touched.goal17TypeId}
                            errors={errors.goal17TypeId}
                          />
                        </Col>
                      </Form.Group>
                      <FormFielErrorMessage>
                        {t('F3_S11_validation2')}
                      </FormFielErrorMessage>
                      <FieldArray
                        name="goal17TargetList"
                        render={(arrayHelpers) => (
                          <>
                            <Accordion>
                              {values.goal17TargetList &&
                              values.goal17TypeId
                                ? values.goal17TargetList.map((target, key) => (
                                    <AccordionItem key={key + 1}>
                                      <AccordionItemHeader>
                                        <AccordionTitle eventKey={key + 1}>
                                          {t("F3_S11_Target")} {key + 1}
                                        </AccordionTitle>
                                      </AccordionItemHeader>
                                      <Accordion.Collapse eventKey={key + 1}>
                                        <AccordionItemBody>
                                          <TargetForm
                                            targetListName={"goal17TargetList"}
                                            targetIndex={key}
                                            goalId="17"
                                            developmentGoalTargets={filterDevGoalsTargets("17")}
                                            targetIndicatorsList={
                                              developmentGoalTargetIndicators
                                            }
                                            setSaved={setSaved}
                                          />
                                          <ErrorButton
                                            onClick={() =>
                                              openModalRemove(key, arrayHelpers)
                                            }
                                          >
                                            {t("F3_S11_Target_button_delete")}
                                          </ErrorButton>
                                        </AccordionItemBody>
                                      </Accordion.Collapse>
                                    </AccordionItem>
                                  ))
                                : null}
                            </Accordion>

                            {typeof errors.goal17TargetList === "string" ? (
                              <FormErrorMessage
                                touched
                                errors={errors.goal17TargetList}
                              />
                            ) : null}

                            <SeparatorDotted />
                            <PrimaryButton
                              onClick={() => arrayHelpers.push(targetObject)}
                            >
                              {t("F3_S11_Target_button_add")}
                            </PrimaryButton>
                          </>
                        )}
                      />
                    </StyledContainer>
                  </form>
                )
              }
            }
        </Formik>
      </FormContainer>
    </FormLayout>
  )
}

export default observer(Section11);

