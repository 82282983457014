import styled, { css } from 'styled-components'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { NavLink } from 'react-router-dom'

export const Container = styled(Navbar)`
  ${({ theme }) => css`
    flex-direction: row;
    padding: 10px 40px;
    border-bottom: 1px solid ${theme.colors.gray[4]};
  `}
`

export const Link = styled(NavLink)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
    font-weight: bold;
    margin-right: 15px;
    &:hover {
      color: ${theme.colors.gray[2]};
      text-decoration: none;
    }
    &.active {
      color: ${theme.brandColors.blue};
    }
  `}
`

export const Dropdown = styled(NavDropdown)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
    .dropdown-menu a:hover {
      color: ${theme.colors.gray[2]} !important;
      background: none !important;
    }
    font-weight: bold;
    margin-right: 15px;
    a:hover {
      color: ${theme.colors.gray[2]} !important;
      text-decoration: none;
      background: none !important;
    }
    .dropdown-menu a:active {
      color: ${theme.brandColors.blue} !important;
      background: none !important;
    }

    .dropdown-menu {
      .dropdown-item.active {
        color: ${theme.colors.gray[1]};
        background: none !important;
      }
    }

    a:focus {
      color: ${theme.brandColors.blue} !important;
      background: none !important;
    }
    margin-top: -8px;
  `}
`

export const DropdownItem = styled(NavDropdown.Item)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
    font-weight: bold;
    margin-right: 15px;
  `}
`

export const DropdownLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.gray[1]};
    font-weight: bold;
    margin-right: 15px;
  `}
`
