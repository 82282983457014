import React, { useState }from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'components/Buttons';

const ExportToXLSXButton = ({
  data,
  mapData,
  data2 = undefined,
  mapData2 = (data2) => [],
  disabled,
  exportName,
  children
}) => {
  const { t } = useTranslation();
  const [exporting, setExporting] = useState(false);
  const [exportData, setExportData] = useState(null);
  const [buttonReady, setButtonReady] = useState(false);

  const handleClick = async () => {
    setExporting(true);
    setExportData([...mapData(data), ...(data2 ? mapData2(data2) : []) ]);
    setButtonReady(true);
    setExporting(false);
  };

  const onDownloadReady = () => {
    setExportData(null);
    setButtonReady(false);
  };

  return (
    <>
      <PrimaryButton
        onClick={handleClick}
        disabled={exporting || disabled}
      >
        { t('Export_to_XLSX') }
      </PrimaryButton>
      <div style={{width: '0px'}}>
        {React.cloneElement(children, {data: exportData, isReady: buttonReady, onReady: onDownloadReady, exportName: exportName})}
      </div>
    </>
  );
};

export default ExportToXLSXButton;